import { Stack, Typography } from "@material-ui/core";

const Empty = ({ title, show }) => {
  if (show)
    return (
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <img
          src="/static/images/emptyData.png"
          width={450}
          height={450}
          style={{
            display: "inline-block",
            maxWidth: "100%",
            width: 400,
            height: 400,
          }}
        />
        <Typography variant="h6" color="text.secondary">
          {title}
        </Typography>
      </Stack>
    );
  else return null;
};

Empty.defaultProps = {
  title: "There are no records",
  show: false,
};

export default Empty;
