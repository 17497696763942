import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  Checkbox,
  TableCell,
  TableRow,
  Button,
} from "@material-ui/core";
import { blockUser, unblockUser } from "src/api";
import { setAlertAction } from "src/redux/app/appActions";
import getTimeStr from "src/utils/getTime";

const CustomerTableBody = ({
  customer,
  selectedCustomerIds,
  handleSelectOne,
}) => {
  const dispatch = useDispatch();

  const [activeStatus, setActiveStatus] = useState(customer.status === 0);

  const changeUserStatusHandler = async () => {
    try {
      setActiveStatus((prev) => !prev);
      if (activeStatus) await blockUser(customer?.id);
      else await unblockUser(customer?.id);
    } catch (error) {
      dispatch(
        setAlertAction({
          status: "error",
          message: "Oops, some problem with the api.",
        })
      );
      setActiveStatus((prev) => !prev);
    }
  };

  return (
    <>
      <TableRow
        hover
        selected={selectedCustomerIds.indexOf(customer.id) !== -1}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectedCustomerIds.indexOf(customer.id) !== -1}
            onChange={(event) => handleSelectOne(event, customer.id)}
            value="true"
          />
        </TableCell>
        <TableCell>
          <Typography variant="body2" noWrap={true}>
            {customer.id}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" noWrap={true}>
            {customer.email}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" noWrap={true}>
            {getTimeStr(customer.createdAt)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" noWrap={true}>
            ${customer.locking}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" noWrap={true}>
            ${customer.pending}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" noWrap={true}>
            ${customer.total}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" noWrap={true}>
            {activeStatus ? "Active" : "Inactive"}
          </Typography>
        </TableCell>
        <TableCell>
          <Button
            color={activeStatus ? "error" : "info"}
            variant="text"
            onClick={changeUserStatusHandler}
          >
            {activeStatus ? "Block" : "Unblock"}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

CustomerTableBody.propTypes = {
  customer: PropTypes.object.isRequired,
  selectedCustomerIds: PropTypes.array.isRequired,
  handleSelectOne: PropTypes.func.isRequired,
};

export default CustomerTableBody;
