import { useState } from "react";
import { MenuItem, TextField } from "@material-ui/core";

const statuses = [
  {
    value: "all",
    label: "All",
  },
  {
    value: 0,
    label: "Pending",
  },
  {
    value: 10,
    label: "Available",
  },
  {
    value: 20,
    label: "Cashing",
  },
  {
    value: 50,
    label: "Cashed",
  },
  {
    value: -20,
    label: "Canceled",
  },
];

const Status = ({ cb }) => {
  const [status, setStatus] = useState("all");

  const handleChange = (event) => {
    const val = event.target.value;
    setStatus(val);
    if (val === "all") cb({ status: "" });
    else cb({ status: val });
  };

  return (
    <TextField
      select
      label="Select status"
      value={status}
      fullWidth
      size="small"
      sx={{ maxWidth: 130 }}
      onChange={handleChange}
    >
      {statuses.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Status;
