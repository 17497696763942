export const types = {
  INITIAL_ORDERS_LOAD: "ORDERS/INITIAL_ORDERS_LOAD",
  INITIAL_ORDERS_LOAD_SUCCESS: "ORDERS/INITIAL_ORDERS_LOAD_SUCCESS",
  INITIAL_PARAMS: "ORDERS/INITIAL_PARAMS",
  REJECT_ORDER: "ORDERS/REJECT_ORDER",
  BULK_REJECT_ORDERS: "ORDERS/BULK_REJECT_ORDERS",
};

export const initialOrdersLoadAction = () => {
  return {
    type: types.INITIAL_ORDERS_LOAD,
  };
};

export const initialOrdersLoadSuccessAction = (orders) => {
  return {
    type: types.INITIAL_ORDERS_LOAD_SUCCESS,
    orders,
  };
};

export const initialParamsAction = (params) => {
  return {
    type: types.INITIAL_PARAMS,
    params,
  };
};

export const rejectOrderAction = (id) => {
  return {
    type: types.REJECT_ORDER,
    id,
  };
};

export const bulkRejectOrdersAction = (ids) => {
  return {
    type: types.BULK_REJECT_ORDERS,
    ids,
  };
};
