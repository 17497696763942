import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import {
  initialLinksLoadAction,
  deleteLinkAction,
} from "src/redux/links/linksActions";
import {
  getPageNum,
  getPageSize,
  getLinks,
  getTotalPages,
} from "src/redux/links/linksSelectors";
import { deleteDeal } from "src/api";
import getUrlParams from "src/utils/getUrlParams";
import LinkTableBody from "./LinkTableBody";

const LinkListResults = ({ ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deals = useSelector(getLinks);
  const page = useSelector(getPageNum);
  const pageSize = useSelector(getPageSize);
  const totalPages = useSelector(getTotalPages);

  const [showModal, setShowModal] = useState(false);
  const [dealId, setDealId] = useState();

  useEffect(() => {
    const query = getUrlParams();

    if (page !== (query?.page || 1)) dispatch(initialLinksLoadAction());
  }, [dispatch]);

  const handleLimitChange = (event) => {
    navigate(`/app/links?page=1&size=${event.target.value}`);
    dispatch(initialLinksLoadAction());
  };

  const handlePageChange = (event, newPage) => {
    navigate(`/app/links?page=${newPage + 1}&size=${pageSize}`);
    dispatch(initialLinksLoadAction());
  };

  const openDeleteDealModal = (id) => {
    setShowModal(true);
    setDealId(id);
  };

  const deleteDealHandler = async () => {
    dispatch(deleteLinkAction(dealId));

    setShowModal(false);
    setDealId(null);

    await deleteDeal(dealId);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Delete this deal forever?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowModal(false)}
            sx={{
              color: "text.primary",
            }}
          >
            Cancel
          </Button>
          <Button onClick={deleteDealHandler} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>CreatDate</TableCell>
                  <TableCell>Original Link</TableCell>
                  <TableCell>Short Link</TableCell>
                  <TableCell>Clicks</TableCell>
                  <TableCell>Comments</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deals.map((deal) => (
                  <LinkTableBody
                    key={deal.id}
                    data={deal}
                    openDeleteDealModal={openDeleteDealModal}
                  />
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        {deals.length > 0 && (
          <TablePagination
            component="div"
            count={totalPages}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page - 1}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[25, 50]}
          />
        )}
      </Card>
    </>
  );
};

export default LinkListResults;
