import { takeLatest, put, call, select } from "redux-saga/effects";
import { types, initialOrdersLoadSuccessAction } from "./ordersActions";
import {
  showLoader,
  hideLoader,
  setAlertAction,
} from "src/redux/app/appActions";
import { getParams } from "./ordersSelectors";
import { getOrderList } from "src/api";
import getUrlParams from "src/utils/getUrlParams";

export function* ordersWatcher() {
  yield takeLatest(types.INITIAL_ORDERS_LOAD, initialOrdersLoadSaga);
  yield takeLatest(types.INITIAL_PARAMS, initialOrdersLoadSaga);
}

function* initialOrdersLoadSaga() {
  try {
    yield put(showLoader());

    const params = yield select(getParams);
    const ordersData = yield call(fetchOrdersData, params);

    yield put(initialOrdersLoadSuccessAction(ordersData));

    yield put(hideLoader());
  } catch ({ data: error }) {
    yield put(
      setAlertAction({
        status: "error",
        message: error?.message || "load error",
      })
    );
    yield put(hideLoader());
  }
}

const fetchOrdersData = async (params) => {
  const query = getUrlParams();

  const { data: ordersData } = await getOrderList({
    size: query?.size || 25,
    page: query?.page || 1,
    ...params,
  });

  return ordersData;
};
