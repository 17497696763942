import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  Button,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { initialPayoutsModalAction } from "src/redux/payouts/payoutsActions";
import getTimeStr from "src/utils/getTime";

const PayOutsTableBody = ({ data }) => {
  const dispatch = useDispatch();

  const returnStatus = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 10:
        return "Approved";
      case -10:
        return "Rejected";

      default:
        return "";
    }
  };

  const returnStatusColor = (status) => {
    switch (status) {
      case 0:
        return "#F2994A";
      case 10:
        return "#5FE25CAB";
      case -10:
        return "#EA5456";

      default:
        return "inherit";
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography>{data?.userEmail}</Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {getTimeStr(data.completedAt || data.createdAt)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography> {data?.comment}</Typography>
        </TableCell>
        <TableCell>
          <Typography>${data?.amount}</Typography>
        </TableCell>
        <TableCell>
          <Typography
            sx={{ color: returnStatusColor(data?.status), fontWeight: 500 }}
          >
            {returnStatus(data?.status)}
          </Typography>
        </TableCell>
        <TableCell>
          <Stack direction="row" spacing={2}>
            <Button
              size="small"
              color="success"
              variant="outlined"
              disabled={data?.status !== 0}
              onClick={() =>
                dispatch(
                  initialPayoutsModalAction({
                    id: data.id,
                    amount: data.amount,
                    size: "lg",
                    payment: data.payment,
                  })
                )
              }
            >
              Aprove
            </Button>{" "}
            <Button
              size="small"
              color="error"
              variant="outlined"
              disabled={data?.status !== 0}
              onClick={() =>
                dispatch(
                  initialPayoutsModalAction({
                    id: data.id,
                    amount: "",
                    size: "sm",
                    payment: null,
                  })
                )
              }
            >
              Reject
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};

PayOutsTableBody.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PayOutsTableBody;
