import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import LinkListResults from "src/components/link/LinkListResults";

const LinkList = () => (
  <>
    <Helmet>
      <title>Links | Kashly</title>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Box sx={{ pt: 3 }}>
          <LinkListResults />
        </Box>
      </Container>
    </Box>
  </>
);

export default LinkList;
