import { takeLatest, put, call } from "redux-saga/effects";
import { types, initialLinksLoadSuccessAction } from "./linksActions";
import {
  showLoader,
  hideLoader,
  setAlertAction,
} from "src/redux/app/appActions";
import { getDealList } from "src/api";
import getUrlParams from "src/utils/getUrlParams";

export function* linksWatcher() {
  yield takeLatest(types.INITIAL_LINKS_LOAD, initialLinksLoadSaga);
}

function* initialLinksLoadSaga() {
  try {
    yield put(showLoader());

    const linksData = yield call(fetchLinksData);

    yield put(initialLinksLoadSuccessAction(linksData));

    yield put(hideLoader());
  } catch ({ data: error }) {
    yield put(
      setAlertAction({
        status: "error",
        message: error?.message || "load error",
      })
    );
    yield put(hideLoader());
  }
}

const fetchLinksData = async () => {
  const query = getUrlParams();
  const { data: linksData } = await getDealList({
    size: query?.size || 25,
    page: query?.page || 1,
  });

  return linksData;
};
