import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Divider, Drawer, List, Typography } from "@material-ui/core";
import {
  ShoppingCart as ShoppingCartIcon,
  Users as UsersIcon,
  LogOut as LogOutIcon,
  Link as LinkIcon,
  CreditCard as CreditCardIcon,
  Command as CommandIcon,
} from "react-feather";
import NavItem from "./NavItem";
import { useAppContext } from "src/context/AppContext";

const items = [
  {
    href: "/app/dashboard",
    icon: CommandIcon,
    title: "Dashboard",
  },
  {
    href: "/app/customers",
    icon: UsersIcon,
    title: "Customers",
  },
  {
    href: "/app/orders",
    icon: ShoppingCartIcon,
    title: "Orders",
  },
  {
    href: "/app/links",
    icon: LinkIcon,
    title: "Links",
  },
  {
    href: "/app/payouts",
    icon: CreditCardIcon,
    title: "Payouts",
  },
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfo, logout } = useAppContext();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const logoutHandler = (e) => {
    e.preventDefault();
    logout();

    navigate("/login");
  };

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          p: 2,
        }}
      >
        <Typography color="textPrimary" variant="h5">
          {userInfo?.email}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          Admin
        </Typography>
      </Box>
      <Divider />

      <List sx={{ p: 2 }}>
        {items.map((item) => (
          <NavItem
            href={item.href}
            key={item.title}
            title={item.title}
            icon={item.icon}
          />
        ))}
      </List>
      <Divider />
      <List sx={{ px: 2 }}>
        <NavItem
          href={"/login"}
          title={"Log Out"}
          icon={LogOutIcon}
          onClick={logoutHandler}
        />
      </List>

      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Box sx={{ display: { xs: "block", lg: "none", xl: "none" } }}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 240,
            },
          }}
        >
          {content}
        </Drawer>
      </Box>
      <Box sx={{ display: { xs: "none", lg: "block", xl: "block" } }}>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 240,
              top: 64,
              height: "calc(100% - 64px)",
            },
          }}
        >
          {content}
        </Drawer>
      </Box>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
