import { takeLatest, put, call } from "redux-saga/effects";
import {
  types,
  showStatsLoader,
  hideStatsLoader,
  initialDashboardLoadSuccessAction,
  initialTopLinksLoadSuccessAction,
  initialChartsLoadSuccessAction,
} from "./dashboardActions";
import {
  showLoader,
  hideLoader,
  setAlertAction,
} from "src/redux/app/appActions";
import { getTopLinks, getDailyClicks, getDailyDeals } from "src/api";
import { getSubtractDate } from "src/utils/getTime";
import getUrlParams from "src/utils/getUrlParams";

export function* dashboardWatcher() {
  yield takeLatest(types.INITIAL_DASHBOARD_LOAD, initialDashboardLoadSaga);
  yield takeLatest(types.INITIAL_TOPLINKS_LOAD, initialTopLinksLoadSaga);
  yield takeLatest(types.INITIAL_CHARTS_LOAD, initialChartsLoadSaga);
}

function* initialDashboardLoadSaga() {
  try {
    yield put(showLoader());

    const data = yield call(fetchStatsData);

    yield put(initialDashboardLoadSuccessAction(data));

    yield put(hideLoader());
  } catch ({ data: error }) {
    yield put(
      setAlertAction({
        status: "error",
        message: error?.message || "load error",
      })
    );
    yield put(hideLoader());
  }
}

const fetchStatsData = async () => {
  const query = getUrlParams();

  const [{ data: topLinks }, { data: clicks }, { data: deals }] =
    await Promise.all([
      getTopLinks({
        date: query?.date || getSubtractDate(1),
      }),
      getDailyClicks({
        startDate: query?.startDate || getSubtractDate(8),
        endDate: query?.endDate || getSubtractDate(1),
      }),
      getDailyDeals({
        startDate: query?.startDate || getSubtractDate(8),
        endDate: query?.endDate || getSubtractDate(1),
      }),
    ]);

  return { topLinks, clicks, deals };
};

function* initialTopLinksLoadSaga() {
  try {
    yield put(showStatsLoader());

    const data = yield call(fetchTopLinksData);

    yield put(initialTopLinksLoadSuccessAction(data));
  } catch ({ data: error }) {
    yield put(
      setAlertAction({
        status: "error",
        message: error?.messa,
      })
    );
  } finally {
    yield put(hideStatsLoader());
  }
}

const fetchTopLinksData = async () => {
  const query = getUrlParams();

  const [{ data: topLinks }] = await Promise.all([
    getTopLinks({
      date: query?.date || getSubtractDate(1),
    }),
  ]);

  return topLinks;
};

function* initialChartsLoadSaga() {
  try {
    yield put(showLoader());

    const data = yield call(fetchChartsData);

    yield put(initialChartsLoadSuccessAction(data));

    yield put(hideLoader());
  } catch ({ data: error }) {
    yield put(
      setAlertAction({
        status: "error",
        message: error?.message || "load error",
      })
    );
    yield put(hideLoader());
  }
}

const fetchChartsData = async () => {
  const query = getUrlParams();

  const [{ data: clicks }, { data: deals }] = await Promise.all([
    getDailyClicks({
      startDate: query?.startDate || getSubtractDate(8),
      endDate: query?.endDate || getSubtractDate(1),
    }),
    getDailyDeals({
      startDate: query?.startDate || getSubtractDate(8),
      endDate: query?.endDate || getSubtractDate(1),
    }),
  ]);

  return { clicks, deals };
};
