import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import EmailSearch from "src/components/widgets/EmailSearch";
import CustomerTableBody from "./CustomerTableBody";
import useCustomers from "./useCustomers";

const CustomerListResults = ({ ...rest }) => {
  const {
    customers,
    page,
    pageSize,
    totalPages,
    selectedCustomerIds,
    handleSelectAll,
    handleSelectOne,
    handleLimitChange,
    handlePageChange,
    updateParamsHandler,
  } = useCustomers();

  return (
    <>
      <Stack direction="row" mb={2} spacing={3}>
        <EmailSearch cb={updateParamsHandler} />
      </Stack>

      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.length === customers.length}
                      color="primary"
                      indeterminate={
                        selectedCustomerIds.length > 0 &&
                        selectedCustomerIds.length < customers.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Locking</TableCell>
                  <TableCell>Pending</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((customer) => (
                  <CustomerTableBody
                    key={customer.id}
                    customer={customer}
                    selectedCustomerIds={selectedCustomerIds}
                    handleSelectOne={handleSelectOne}
                  />
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        {customers.length > 0 && (
          <TablePagination
            component="div"
            count={totalPages}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page - 1}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[25, 50]}
          />
        )}
      </Card>
    </>
  );
};

export default CustomerListResults;
