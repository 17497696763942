import { useEffect, useState, useCallback } from "react";
import useDebounce from "src/hooks/debounce.hook";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import StoreIcon from "@material-ui/icons/Store";
import ClearIcon from "@material-ui/icons/Clear";
import { searchStores } from "src/api";

const StoreSearch = ({
  cb,
  defVal = { label: "", storeId: "" },
  label = "Stores",
  margin = "none",
}) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [input, setInput] = useState("");
  const [store, setStore] = useState(defVal);

  const debouncedSearchTerm = useDebounce(input, 200);

  const search = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await searchStores({
        pageSize: 10,
        text: encodeURIComponent(debouncedSearchTerm),
      });

      const optns = data?.data?.map((v) => {
        return {
          label: v.name,
          storeId: v.id,
        };
      });

      setOptions(optns);
      setLoading(false);
    } catch (error) {}
  }, [debouncedSearchTerm]);

  useEffect(() => {
    search();
  }, [search]);

  const handleInputChange = (e) => {
    const val = e.target.value;
    if (!!val.trim()) setInput(val);
  };

  const handleChange = (val) => {
    setStore(val);
    cb({ storeId: val.storeId });
  };

  return (
    <>
      <Autocomplete
        disableClearable
        options={options}
        value={store?.label}
        onChange={(event, newValue) => handleChange(newValue)}
        loading={loading}
        fullWidth
        sx={{ maxWidth: 220 }}
        renderInput={(params) => (
          <TextField
            {...params}
            color="secondary"
            size="small"
            margin={margin}
            label={label}
            onChange={handleInputChange}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <StoreIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                      sx={{ mr: 4 }}
                    />
                  ) : (
                    <IconButton
                      size="small"
                      onClick={() => handleChange(defVal)}
                      disabled={!store?.storeId}
                      sx={{ mr: 3 }}
                    >
                      <ClearIcon color="inherit" sx={{ fontSize: 18 }} />
                    </IconButton>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default StoreSearch;
