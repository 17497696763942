import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { Stack, Typography } from "@material-ui/core";
import { initialChartsLoadAction } from "src/redux/dashboard/dashboardActions";
import { getSubtractDate } from "src/utils/getTime";
import getUrlParams from "src/utils/getUrlParams";
import useChartsData from "./useChartsData";
import DatePicker from "../widgets/DatePicker";

const DailyCharts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = getUrlParams();

  const { clicksCountData, platformsCountData, devicesCountData, dealsData } =
    useChartsData();

  const filterByDate = ({ startDate, endDate }) => {
    navigate(`/app/dashboard?startDate=${startDate}&endDate=${endDate}`);
    dispatch(initialChartsLoadAction());
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h3">
          Stats from {params?.startDate || getSubtractDate(8)} to{" "}
          {params?.endDate || getSubtractDate(1)}
        </Typography>

        <DatePicker cb={filterByDate} />
      </Stack>
      <Stack direction="row" flexWrap="wrap" justifyContent="space-between">
        <Stack spacing={2} width="50%">
          <Typography variant="h4">Total Clicks</Typography>
          <Bar data={clicksCountData} />
        </Stack>

        <Stack spacing={2} width="50%">
          <Typography variant="h4">Total Platforms Clicks</Typography>
          <Bar data={platformsCountData} />
        </Stack>

        <Stack spacing={2} width="50%" mt={5}>
          <Typography variant="h4">Total Short Links</Typography>
          <Bar data={dealsData} />
        </Stack>
        <Stack spacing={2} width="50%" mt={5}>
          <Typography variant="h4">Total Devices Clicks</Typography>
          <Bar data={devicesCountData} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DailyCharts;
