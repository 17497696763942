import { Stack, Typography, Link, Divider } from "@material-ui/core";

const LinkItem = ({ link }) => {
  return (
    <>
      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Link href={link.link} underline="hover" sx={{ color: "text.primary" }}>
          <Typography width={250}>{link.link}</Typography>
        </Link>

        <Link
          href={link.originLink}
          underline="hover"
          sx={{ color: "text.primary" }}
        >
          <Typography width={700} className="title-2">
            {link.originLink}
          </Typography>
        </Link>

        <Typography>{link.count}</Typography>
      </Stack>
      <Divider light />
    </>
  );
};

export default LinkItem;
