import { useContext, createContext } from "react";
import useAuth from "src/hooks/useAuth";

const AppContext = createContext({
  token: null,
  isAuthenticated: false,
  userInfo: {},
});

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
  const context = useAuth();

  return (
    <AppContext.Provider
      value={{
        ...context,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
