import { Navigate } from "react-router-dom";
import DashboardLayout from "src/components/DashboardLayout";
import MainLayout from "src/components/MainLayout";
import Dashboard from "./pages/Dashboard";
import CustomerList from "src/pages/CustomerList";
import Login from "src/pages/Login";
import NotFound from "src/pages/NotFound";
import OrderList from "src/pages/OrderList";
import LinkList from "src/pages/LinkList";
import Payouts from "src/pages/Payouts";

const routes = [
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      { path: "dashboard", element: <Dashboard /> },
      { path: "customers", element: <CustomerList /> },
      { path: "orders", element: <OrderList /> },
      { path: "links", element: <LinkList /> },
      { path: "payouts", element: <Payouts /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "login", element: <Login /> },
      { path: "404", element: <NotFound /> },
      { path: "/", element: <Navigate to="/login" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
