export const types = {
  SHOW_LOADER: "DSH/SHOW_LOADER",
  HIDE_LOADER: "DSH/HIDE_LOADER",
  INITIAL_DASHBOARD_LOAD: "DSH/INITIAL_DASHBOARD_LOAD",
  INITIAL_DASHBOARD_LOAD_SUCCESS: "DSH/INITIAL_DASHBOARD_LOAD_SUCCESS",
  INITIAL_TOPLINKS_LOAD: "DSH/INITIAL_TOPLINKS_LOAD",
  INITIAL_TOPLINKS_LOAD_SUCCESS: "DSH/INITIAL_TOPLINKS_LOAD_SUCCESS",
  INITIAL_CHARTS_LOAD: "DSH/INITIAL_CHARTS_LOAD",
  INITIAL_CHARTS_LOAD_SUCCESS: "DSH/INITIAL_CHARTS_LOAD_SUCCESS",
};

export function showStatsLoader() {
  return {
    type: types.SHOW_LOADER,
  };
}

export function hideStatsLoader() {
  return {
    type: types.HIDE_LOADER,
  };
}

export const initialDashboardLoadAction = () => {
  return {
    type: types.INITIAL_DASHBOARD_LOAD,
  };
};

export const initialDashboardLoadSuccessAction = (data) => {
  return {
    type: types.INITIAL_DASHBOARD_LOAD_SUCCESS,
    data,
  };
};

export const initialTopLinksLoadAction = () => {
  return {
    type: types.INITIAL_TOPLINKS_LOAD,
  };
};

export const initialTopLinksLoadSuccessAction = (links) => {
  return {
    type: types.INITIAL_TOPLINKS_LOAD_SUCCESS,
    links,
  };
};

export const initialChartsLoadAction = () => {
  return {
    type: types.INITIAL_CHARTS_LOAD,
  };
};

export const initialChartsLoadSuccessAction = (data) => {
  return {
    type: types.INITIAL_CHARTS_LOAD_SUCCESS,
    data,
  };
};
