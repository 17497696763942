import { types } from "./usersActions";

const initialState = {
  users: {
    data: [],
    page: 0,
    count: 0,
    size: 25,
  },
  params: {
    email: null,
  },
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INITIAL_USERS_LOAD_SUCCESS:
      return {
        ...state,
        users: action.users,
      };

    case types.INITIAL_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };

    default:
      return state;
  }
};
