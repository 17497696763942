import { useState, useEffect } from "react";
import useDebounce from "src/hooks/debounce.hook";
import { InputAdornment, TextField } from "@material-ui/core";
import TagIcon from "@material-ui/icons/Tag";

const OrderNoSearch = ({ cb }) => {
  const [val, setValue] = useState("");

  const debouncedSearchTerm = useDebounce(val, 300);
  useEffect(() => {
    cb({ orderNo: debouncedSearchTerm });
  }, [debouncedSearchTerm]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      <TextField
        label="Search by orderNo"
        name="orderNo"
        type="text"
        value={val}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <TagIcon />
            </InputAdornment>
          ),
        }}
        size="small"
        fullWidth
        sx={{ maxWidth: 180 }}
      />
    </>
  );
};

export default OrderNoSearch;
