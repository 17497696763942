import { useState } from "react";
import { Stack, TextField, Button } from "@material-ui/core";

const RejectContent = ({ id, cb, confirmCb }) => {
  const [comment, setComment] = useState("");

  const submitHandler = () => {
    confirmCb(comment);
  };

  return (
    <Stack spacing={4} sx={{ pt: 1 }}>
      <TextField
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        label="Comment"
        minRows={4}
        maxRows={4}
        multiline
        fullWidth
      />

      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Button
          variant="contained"
          color="success"
          onClick={submitHandler}
          sx={{ color: "#fff" }}
        >
          Ok
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={cb}
          sx={{ color: "#fff" }}
        >
          Close
        </Button>
      </Stack>
    </Stack>
  );
};

export default RejectContent;
