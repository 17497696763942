import { types } from "./linksActions";

const initialState = {
  links: {
    data: [],
    page: 0,
    count: 0,
    size: 25,
  },
};

export const linksReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INITIAL_LINKS_LOAD_SUCCESS:
      return {
        ...state,
        links: action.links,
      };

    case types.DELETE_LINK:
      const newLinks = [...state.links.data].filter(
        (el) => el.id !== action.id
      );
      return {
        ...state,
        links: { ...state.links, data: newLinks },
      };

    default:
      return state;
  }
};
