import { useSelector } from "react-redux";
import {
  getDailyClicks,
  getDailyDeals,
} from "src/redux/dashboard/dashboardSelectors";

const useChartsData = () => {
  const clicks = useSelector(getDailyClicks);
  const deals = useSelector(getDailyDeals);

  const clicksCountData = {
    datasets: [
      {
        backgroundColor: "#5932EA",
        data: clicks.map((el) => el.count),
        label: `Clicks count`,
      },
    ],
    labels: clicks.map((el) => el.date),
  };

  const platformsCountData = {
    datasets: [
      {
        backgroundColor: "#F38181",
        data: clicks.map((el) => el?.platforms?.desktop || 0),
        label: `Desktop`,
      },
      {
        backgroundColor: "#6FE7DD",
        data: clicks.map((el) => el?.platforms?.mobile || 0),
        label: `Mobile`,
      },
      {
        backgroundColor: "#FF9DE2",
        data: clicks.map((el) => el?.platforms?.other || 0),
        label: `Other`,
      },
    ],
    labels: clicks.map((el) => el.date),
  };

  const devicesCountData = {
    datasets: [
      {
        backgroundColor: "#A1DE93",
        data: clicks.map((el) => el?.devices?.android || 0),
        label: `Android`,
      },
      {
        backgroundColor: "#384259",
        data: clicks.map((el) => el?.devices?.iPhone || 0),
        label: `iPhone`,
      },
      {
        backgroundColor: "#74F9FF",
        data: clicks.map((el) => el?.devices?.linux || 0),
        label: `Linux`,
      },
      {
        backgroundColor: "#F85F73",
        data: clicks.map((el) => el?.devices?.mac || 0),
        label: `Mac`,
      },
      {
        backgroundColor: "#3490DE",
        data: clicks.map((el) => el?.devices?.windows || 0),
        label: `Windows`,
      },
      {
        backgroundColor: "#FF9DE2",
        data: clicks.map((el) => el?.devices?.other || 0),
        label: `Other`,
      },
    ],
    labels: clicks.map((el) => el.date),
  };

  const dealsData = {
    datasets: [
      {
        backgroundColor: "#AA96DA",
        data: deals.map((el) => el.count),
        label: `Links count`,
      },
    ],
    labels: deals.map((el) => el.date),
  };
  return { clicksCountData, platformsCountData, devicesCountData, dealsData };
};

export default useChartsData;
