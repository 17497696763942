import { takeLatest, put, call, select } from "redux-saga/effects";
import { types, initialUsersLoadSuccessAction } from "./usersActions";
import {
  showLoader,
  hideLoader,
  setAlertAction,
} from "src/redux/app/appActions";
import { getParams } from "./usersSelectors";
import { getUserList } from "src/api";
import getUrlParams from "src/utils/getUrlParams";

export function* usersWatcher() {
  yield takeLatest(types.INITIAL_USERS_LOAD, initialUsersLoadSaga);
  yield takeLatest(types.INITIAL_PARAMS, initialUsersLoadSaga);
}

function* initialUsersLoadSaga() {
  try {
    yield put(showLoader());

    const params = yield select(getParams);
    const usersData = yield call(fetchUsersData, params);

    yield put(initialUsersLoadSuccessAction(usersData));

    yield put(hideLoader());
  } catch ({ data: error }) {
    yield put(
      setAlertAction({
        status: "error",
        message: error?.message || "load error",
      })
    );
    yield put(hideLoader());
  }
}

const fetchUsersData = async (params) => {
  const query = getUrlParams();

  const { data: usersData } = await getUserList({
    size: query?.size || 25,
    page: query?.page || 1,
    ...params,
  });

  return usersData;
};
