import { combineReducers } from "redux";
import { appReducer } from "./app/appReducer";
import { usersReducer } from "./users/usersReducer";
import { ordersReducer } from "./orders/ordersReducer";
import { linksReducer } from "./links/linksReducer";
import { payoutsReducer } from "./payouts/payoutsReducer";
import { dashboardReducer } from "./dashboard/dashboardReducer";

export const rootReducer = combineReducers({
  app: appReducer,
  userList: usersReducer,
  orderList: ordersReducer,
  linkList: linksReducer,
  payouts: payoutsReducer,
  dashboard: dashboardReducer,
});
