import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Stack,
  TableSortLabel,
  Button,
} from "@material-ui/core";
import useOrders from "./useOrders";
import OrderTableBody from "./OrderTableBody";
import DatePicker from "src/components/widgets/DatePicker";
import EmailSearch from "src/components/widgets/EmailSearch";
import StoreSearch from "src/components/widgets/StoreSearch";
import Status from "src/components/widgets/Status";
import OrderNoSearch from "src/components/widgets/OrderNoSearch";
import RejectModal from "./RejectModal";

const OrderListResults = ({ ...rest }) => {
  const {
    orders,
    page,
    pageSize,
    totalPages,
    sortOrder,
    showModal,
    selectedOrderIds,
    handleSelectAll,
    handleSelectOne,
    openRejectModal,
    closeRejectModal,
    rejectOrderHandler,
    handleLimitChange,
    handlePageChange,
    filterHanlder,
    updateParamsHandler,
  } = useOrders();

  return (
    <>
      <RejectModal
        open={showModal}
        closeCb={closeRejectModal}
        rejectCb={rejectOrderHandler}
      />

      <Stack mb={3} spacing={2}>
        <Stack direction="row" spacing={3}>
          <EmailSearch cb={updateParamsHandler} />
          <StoreSearch label="Search by store" cb={updateParamsHandler} />
          <OrderNoSearch cb={updateParamsHandler} />
          <Status cb={updateParamsHandler} />
        </Stack>

        <Stack direction="row" spacing={3}>
          <DatePicker
            cb={filterHanlder}
            offStartDateDefVal={true}
            haveRange={false}
          />
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={() => openRejectModal()}
          >
            Bulk Reject
          </Button>
        </Stack>
      </Stack>

      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedOrderIds.length === orders.length}
                      color="primary"
                      indeterminate={
                        selectedOrderIds.length > 0 &&
                        selectedOrderIds.length < orders.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Order No.</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Store</TableCell>
                  <TableCell>Affiliate</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Cashback</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={true}
                      direction={sortOrder}
                      onClick={() =>
                        updateParamsHandler({
                          sortOrder: sortOrder === "desc" ? "asc" : "desc",
                        })
                      }
                    >
                      Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <OrderTableBody
                    key={order.id}
                    data={order}
                    openRejectCb={openRejectModal}
                    selectedOrderIds={selectedOrderIds}
                    handleSelectOne={handleSelectOne}
                  />
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        {orders.length > 0 && (
          <TablePagination
            component="div"
            count={totalPages}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page - 1}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[25, 50, 100]}
          />
        )}
      </Card>
    </>
  );
};

export default OrderListResults;
