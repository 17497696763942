import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import useSWR from "swr";
import { getUserInfo } from "src/api";

export default function useAuth() {
  const [userInfo, setUserInfo] = useState({});
  const [token, setToken] = useState(() => {
    return (
      Cookies.get(
        "ad_token",
        process.env.NODE_ENV === "production"
          ? { domain: "admin.kashly.net" }
          : {}
      ) || null
    );
  });

  const { data: user, mutate } = useSWR(
    !!token ? ["/user", token] : null,
    getUserInfo
  );

  const login = (userData) => {
    setToken(userData.token);
    setUserInfo(userData.data);

    const params = {
      expires: 30,
      path: "/",
    };
    if (process.env.NODE_ENV === "production")
      params.domain = "admin.kashly.net";

    Cookies.set("ad_token", userData.token, params);
    mutate();
  };

  const logout = () => {
    setToken(null);
    localStorage.clear();

    const params = {
      expires: 30,
      path: "/",
    };
    if (process.env.NODE_ENV === "production")
      params.domain = "admin.kashly.net";

    Cookies.remove("ad_token", params);
    mutate(null);
  };

  useEffect(() => {
    if (user) setUserInfo(user.data.data);
  }, [user]);

  return { userInfo, token, logout, login };
}
