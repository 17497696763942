import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Box, Container, Stack } from "@material-ui/core";
import { initialDashboardLoadAction } from "src/redux/dashboard/dashboardActions";
import DailyLinks from "src/components/dashboard/DailyLinks";
import DailyCharts from "src/components/dashboard/DailyCharts";

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialDashboardLoadAction());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Dashboard | Kashly</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Stack spacing={10}>
            <DailyCharts />
            <DailyLinks />
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
