import { types } from "./payoutsActions";

const initialState = {
  payouts: {
    data: [],
    page: 0,
    count: 0,
    size: 25,
  },
  modal: {
    id: null,
    amount: null,
    size: "lg",
    payment: null,
  },
};

export const payoutsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INITIAL_PAYOUTS_LOAD_SUCCESS:
      return {
        ...state,
        payouts: action.payouts,
      };

    case types.INITIAL_PAYOUTS_MODAL:
      return {
        ...state,
        modal: action.modal,
      };

    default:
      return state;
  }
};
