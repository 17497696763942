export const types = {
  INITIAL_LINKS_LOAD: "LINKS/INITIAL_LINKS_LOAD",
  INITIAL_LINKS_LOAD_SUCCESS: "LINKS/INITIAL_LINKS_LOAD_SUCCESS",
  DELETE_LINK: "LINKS/DELETE_LINK",
};

export const initialLinksLoadAction = () => {
  return {
    type: types.INITIAL_LINKS_LOAD,
  };
};

export const initialLinksLoadSuccessAction = (links) => {
  return {
    type: types.INITIAL_LINKS_LOAD_SUCCESS,
    links,
  };
};

export const deleteLinkAction = (id) => {
  return {
    type: types.DELETE_LINK,
    id,
  };
};
