import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "src/context/AppContext";
import { AppBar, Box, IconButton, Toolbar, Button } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CachedIcon from "@material-ui/icons/Cached";
import Logo from "./Logo";

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const navigate = useNavigate();
  const { token } = useAppContext();

  const { pathname } = useLocation();

  const clearFilterParams = () => {
    navigate(pathname);
    window.location.reload();
  };

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to={!!token ? "/app/dashboard" : "/login"}>
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: "none", lg: "block", xl: "block" } }}>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            endIcon={<CachedIcon />}
            onClick={clearFilterParams}
          >
            Reset params
          </Button>
        </Box>
        <Box sx={{ display: { xs: "block", lg: "none", xl: "none" } }}>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;
