import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { initialPayoutsLoadAction } from "src/redux/payouts/payoutsActions";
import {
  getPageNum,
  getPageSize,
  getPayouts,
  getTotalPages,
} from "src/redux/payouts/payoutsSelectors";
import PayOutsTableBody from "./PayOutsTableBody";
import getUrlParams from "src/utils/getUrlParams";
import PayOutModal from "./modal/PayOutModal";

const PayOutsListResults = ({ ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const payouts = useSelector(getPayouts);
  const page = useSelector(getPageNum);
  const pageSize = useSelector(getPageSize);
  const totalPages = useSelector(getTotalPages);

  useEffect(() => {
    const query = getUrlParams();

    if (page !== (query?.page || 1)) dispatch(initialPayoutsLoadAction());
  }, [dispatch]);

  const handleLimitChange = (event) => {
    navigate(`/app/payouts?page=1&size=${event.target.value}`);
    dispatch(initialPayoutsLoadAction());
  };

  const handlePageChange = (event, newPage) => {
    navigate(`/app/payouts?page=${newPage + 1}&size=${pageSize}`);
    dispatch(initialPayoutsLoadAction());
  };

  return (
    <>
      <PayOutModal />

      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Result</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payouts.map((payout) => (
                  <PayOutsTableBody key={payout.id} data={payout} />
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        {payouts.length > 0 && (
          <TablePagination
            component="div"
            count={totalPages}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page - 1}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[25, 50]}
          />
        )}
      </Card>
    </>
  );
};

export default PayOutsListResults;
