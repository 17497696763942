import { types } from "./dashboardActions";

const initialState = {
  topLinks: {
    data: [],
    date: "",
  },
  clicks: [],
  deals: [],
  loading: false,
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INITIAL_DASHBOARD_LOAD_SUCCESS:
      return {
        ...state,
        topLinks: action.data.topLinks,
        clicks: action.data.clicks.data,
        deals: action.data.deals.data,
      };

    case types.INITIAL_CHARTS_LOAD_SUCCESS:
      return {
        ...state,
        clicks: action.data.clicks.data,
        deals: action.data.deals.data,
      };

    case types.INITIAL_TOPLINKS_LOAD_SUCCESS:
      return {
        ...state,
        topLinks: action.links,
      };

    case types.SHOW_LOADER:
      return { ...state, loading: true };
    case types.HIDE_LOADER:
      return { ...state, loading: false };

    default:
      return state;
  }
};
