import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  initialParamsAction,
  initialUsersLoadAction,
} from "src/redux/users/usersActions";
import {
  getPageNum,
  getPageSize,
  getUsers,
  getTotalPages,
} from "src/redux/users/usersSelectors";
import getUrlParams from "src/utils/getUrlParams";

const useCustomers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customers = useSelector(getUsers);
  const page = useSelector(getPageNum);
  const pageSize = useSelector(getPageSize);
  const totalPages = useSelector(getTotalPages);

  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);

  useEffect(() => {
    const query = getUrlParams();

    if (page !== (query?.page || 1)) dispatch(initialUsersLoadAction());
  }, [dispatch]);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    navigate(`/app/customers?page=1&size=${event.target.value}`);
    dispatch(initialUsersLoadAction());
  };

  const handlePageChange = (event, newPage) => {
    navigate(`/app/customers?page=${newPage + 1}&size=${pageSize}`);
    dispatch(initialUsersLoadAction());
  };

  const updateParamsHandler = (param) => {
    dispatch(initialParamsAction(param));
  };

  return {
    customers,
    page,
    pageSize,
    totalPages,
    selectedCustomerIds,
    handleSelectAll,
    handleSelectOne,
    handleLimitChange,
    handlePageChange,
    updateParamsHandler,
  };
};

export default useCustomers;
