import "react-perfect-scrollbar/dist/css/styles.css";
import { useRoutes } from "react-router-dom";
import { AppProvider } from "./context/AppContext";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core";
import GlobalStyles from "src/components/GlobalStyles";
import AppAlert from "src/components/AppAlert";
import "src/mixins/chartjs";
import theme from "src/theme";
import routes from "src/routes";
import "./styles/main.css";

const App = () => {
  const routing = useRoutes(routes);

  return (
    <AppProvider>
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <GlobalStyles />
        <AppAlert />
        {routing}
      </ThemeProvider>
    </AppProvider>
  );
};

export default App;
