import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Stack, Typography } from "@material-ui/core";
import {
  getTopLinks,
  getLoading,
} from "src/redux/dashboard/dashboardSelectors";
import { getSubtractDate } from "src/utils/getTime";
import getUrlParams from "src/utils/getUrlParams";
import { initialTopLinksLoadAction } from "src/redux/dashboard/dashboardActions";
import DateInput from "../widgets/DateInput";
import LinkItem from "./LinkItem";
import Empty from "../Empty";

const DailyLinks = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = getUrlParams();

  const links = useSelector(getTopLinks);
  const loading = useSelector(getLoading);

  const filterByDate = (date) => {
    navigate(`/app/dashboard?date=${date}`);
    dispatch(initialTopLinksLoadAction());
  };

  return (
    <Stack spacing={3} maxWidth={1550}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h3">
          Top 10 Links on {params?.date || getSubtractDate(1)}
        </Typography>

        <DateInput cb={filterByDate} />
      </Stack>

      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="subtitle2" width={260} color="text.secondary">
          Links
        </Typography>
        <Typography variant="subtitle2" width={700} color="text.secondary">
          Short Links
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          Clicks
        </Typography>
      </Stack>

      {loading ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          minHeight={600}
        >
          <CircularProgress />{" "}
        </Stack>
      ) : (
        links.map((link) => <LinkItem key={link.link} link={link} />)
      )}

      <Empty show={!links.length} />
    </Stack>
  );
};

export default DailyLinks;
