import server from "../utils/server";

// Auth apis
export function signIn(data) {
  return server({
    url: "/auth/login/admin",
    method: "post",
    data: data,
    datatype: "json",
  });
}

// Account apis
export function getUserInfo() {
  return server({
    url: "/user",
    method: "get",
    params: {},
    datatype: "json",
  });
}
export function blockUser(slug) {
  return server({
    url: `/users/${slug}/block`,
    method: "post",
    data: {},
    datatype: "json",
  });
}
export function unblockUser(slug) {
  return server({
    url: `/users/${slug}/unblock`,
    method: "post",
    params: {},
    datatype: "json",
  });
}

// Users api
export function getUserList(data) {
  return server({
    url: "/users",
    method: "get",
    params: data,
    datatype: "json",
  });
}

// Deals apis
export function getDealList(data) {
  return server({
    url: "/deals",
    method: "get",
    params: data,
    datatype: "json",
  });
}

export function deleteDeal(id) {
  return server({
    url: `/deals/${id}`,
    method: "delete",
    data: {},
    datatype: "json",
  });
}

// Orders
export function getOrderList(data) {
  return server({
    url: "/orders",
    method: "get",
    params: data,
    datatype: "json",
  });
}

export function rejectOrder(id) {
  return server({
    url: `/orders/${id}/reject`,
    method: "post",
    data: {},
    datatype: "json",
  });
}

export function bulkRejectOrders(data) {
  return server({
    url: `/orders/reject`,
    method: "post",
    data,
    datatype: "json",
  });
}

// Payouts
export function getPayoutsList(data) {
  return server({
    url: "/withdraws",
    method: "get",
    params: data,
    datatype: "json",
  });
}

export function getPayoutsOrders(data, id) {
  return server({
    url: `/withdraws/${id}/orders`,
    method: "get",
    params: data,
    datatype: "json",
  });
}

export function payoutsConfirm(withdrawId, data) {
  return server({
    url: `/withdraws/${withdrawId}/complete`,
    method: "post",
    data: data,
    datatype: "json",
  });
}

export function payoutsCancel(withdrawId, data) {
  return server({
    url: `/withdraws/${withdrawId}/cancel`,
    method: "post",
    data: data,
    datatype: "json",
  });
}

// Dashboard
export function getTopLinks(data) {
  return server({
    url: `/stats/dailyTopLinks`,
    method: "get",
    params: data,
    datatype: "json",
  });
}

export function getDailyClicks(data) {
  return server({
    url: `/stats/dailyClicks`,
    method: "get",
    params: data,
    datatype: "json",
  });
}

export function getDailyDeals(data) {
  return server({
    url: `/stats/dailyDeals`,
    method: "get",
    params: data,
    datatype: "json",
  });
}

export function searchStores(data) {
  return server({
    url: `/stores/search`,
    method: "get",
    params: data,
    datatype: "json",
  });
}
