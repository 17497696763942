import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import OrderListResults from "src/components/order/OrderListResults";

const OrderList = () => (
  <>
    <Helmet>
      <title>Orders | Kashly</title>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 3,
      }}
    >
      <Container maxWidth={false} sx={{ pt: 3 }}>
        <OrderListResults />
      </Container>
    </Box>
  </>
);

export default OrderList;
