import { useState, useRef, useLayoutEffect } from "react";
import { Stack, TextField } from "@material-ui/core";
import { getSubtractDate } from "src/utils/getTime";
import getUrlParams from "src/utils/getUrlParams";

const DatePicker = ({ cb, offStartDateDefVal = false, haveRange = true }) => {
  const params = getUrlParams();

  const startDateInput = useRef(null);
  const endDateInput = useRef(null);

  const [date, setDate] = useState(() => {
    if (offStartDateDefVal)
      return {
        startDate: "",
        endDate: getSubtractDate(0),
      };
    else
      return {
        startDate: params?.startDate || getSubtractDate(8),
        endDate: params?.endDate || getSubtractDate(1),
      };
  });

  const [error, setError] = useState({
    startDate: "",
    endDate: "",
  });

  useLayoutEffect(() => {
    haveRange && setDateRange();
  }, [haveRange]);

  const setDateRange = () => {
    startDateInput.current.min = getSubtractDate(90);
    startDateInput.current.max = getSubtractDate(offStartDateDefVal ? 0 : 1);

    endDateInput.current.min = getSubtractDate(90);
    endDateInput.current.max = getSubtractDate(offStartDateDefVal ? 0 : 1);
  };

  const handleChange = (e) => {
    const val = e?.target?.value;
    const name = e?.target?.name;

    const newDate = {
      ...date,
      [name]: val,
    };

    setDate(newDate);

    if (
      !!newDate?.startDate &&
      !!newDate?.endDate &&
      newDate.startDate > newDate.endDate
    ) {
      setError({
        ...error,
        [name]: `${name} is incorrect!`,
      });
      return;
    } else
      setError({
        startDate: "",
        endDate: "",
      });

    cb(newDate);
  };

  return (
    <Stack direction="row" alignItems="flex-start" spacing={1} width={330}>
      <TextField
        inputRef={startDateInput}
        label="Select start date"
        name="startDate"
        type="date"
        value={date?.startDate}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!error.startDate}
        helperText={error.startDate}
        size="small"
        fullWidth
        sx={{ maxWidth: 160 }}
      />
      <TextField
        inputRef={endDateInput}
        label="Select end date"
        name="endDate"
        type="date"
        value={date?.endDate}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!error.endDate}
        helperText={error.endDate}
        size="small"
        fullWidth
        sx={{ maxWidth: 160 }}
      />
    </Stack>
  );
};

export default DatePicker;
