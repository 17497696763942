import { useEffect, useState } from "react";
import {
  Card,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getPayoutsOrders } from "src/api";
import getTimeStr from "src/utils/getTime";

const AproveContent = ({ id, amount, cb, confirmCb, payment }) => {
  console.log(payment);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [orders, setOrderData] = useState([]);

  const [comment, setComment] = useState("");

  useEffect(() => {
    let isMounted = true;

    const fetchPayoutOrders = async () => {
      try {
        if (!!id) {
          setLoading(true);
          const { data: ordersData } = await getPayoutsOrders(
            {
              size: 10,
              page: page || 1,
              admin: true,
            },
            id
          );

          if (isMounted) {
            setOrderData(ordersData?.data);
            setPage(ordersData?.page || 1);
            setCount(ordersData.count);
            setLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchPayoutOrders();

    return () => {
      isMounted = false;
    };
  }, [page, id]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const submitHandler = () => {
    confirmCb(comment);
  };

  const skeletonData = () => {
    let tempArr = new Array(10).fill(0);

    return tempArr.map((el, index) => (
      <TableRow key={index}>
        <TableCell>
          <Typography variant="subtitle2">
            <Skeleton variant="text" />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">
            <Skeleton variant="text" />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">
            <Skeleton variant="text" />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">
            <Skeleton variant="text" />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">
            <Skeleton variant="text" />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">
            <Skeleton variant="text" />
          </Typography>
        </TableCell>
      </TableRow>
    ));
  };

  const getPaymentStatus = (status) => {
    switch (status) {
      case 30:
        return "Zelle";

      case 20:
        return "Check";

      case 10:
        return "Alipay";

      default:
        break;
    }
  };

  return (
    <>
      <Stack direction="row" spacing={6} sx={{ mb: 4, pt: 1 }}>
        <TextField
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          label="Comment"
          minRows={4}
          maxRows={4}
          multiline
          fullWidth
        />
        <Stack sx={{ width: "100%" }}>
          {!!payment &&
            Object.keys(payment || {}).map((key) => {
              if (
                !key.toLowerCase().includes("id") &&
                !key.toLowerCase().includes("isdefault") &&
                !key.toLowerCase().includes("status")
              ) {
                return (
                  <Stack
                    key={key}
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography
                      variant="h5"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {key}:
                    </Typography>
                    <Typography variant="body1">
                      {key === "type"
                        ? getPaymentStatus(payment[key])
                        : payment[key]}
                    </Typography>
                  </Stack>
                );
              } else return null;
            })}
        </Stack>
      </Stack>
      <Typography variant="h5" sx={{ mb: 4 }}>
        Pay Out Available Balance: ${amount}
      </Typography>
      <Card>
        <PerfectScrollbar>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body2">Date</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">Store</Typography>
                </TableCell>
                {/* <TableCell>AF</TableCell> */}
                <TableCell>
                  <Typography variant="body2">Order No.</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">Amount</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">Cashback</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">User Cashback</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? skeletonData()
                : orders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {getTimeStr(order?.completedAt || order?.createdAt)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {order?.storeName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {order?.orderNo}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          ${order?.amount || 0}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          ${order?.cashback || 0}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          ${order?.userCashback || 0}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>

        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          page={page - 1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </Card>

      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        sx={{ mt: 4 }}
      >
        <Button
          variant="contained"
          color="success"
          onClick={submitHandler}
          sx={{ color: "#fff" }}
        >
          Ok
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={cb}
          sx={{ color: "#fff" }}
        >
          Close
        </Button>
      </Stack>
    </>
  );
};

export default AproveContent;
