import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import {
  initialPayoutsModalAction,
  initialPayoutsLoadAction,
} from "src/redux/payouts/payoutsActions";
import {
  getModalId,
  getModalSize,
  getAmount,
  getPayment,
} from "src/redux/payouts/payoutsSelectors";
import AproveContent from "./AproveContent";
import RejectContent from "./RejectContent";
import { payoutsConfirm, payoutsCancel } from "src/api";

const PayOutModal = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");

  const modalId = useSelector(getModalId);
  const amount = useSelector(getAmount);
  const size = useSelector(getModalSize);
  const payment = useSelector(getPayment);

  const handleClose = () => {
    dispatch(
      initialPayoutsModalAction({
        id: null,
        amount: "",
        size: size,
        payment: null,
      })
    );
  };

  const handleOpenConfirm = (com) => {
    setOpen(true);
    setComment(com);
  };

  const handleCloseConfirm = () => {
    setOpen(false);
    setComment("");
  };

  const submitHandler = async () => {
    try {
      if (size === "lg") {
        await payoutsConfirm(modalId, {
          comment,
        });
      }
      if (size === "sm") {
        await payoutsCancel(modalId, {
          comment,
        });
      }

      dispatch(initialPayoutsLoadAction());
      handleCloseConfirm();
      handleClose();
    } catch (error) {}
  };

  return (
    <>
      <Dialog fullWidth={true} maxWidth="xs" open={open}>
        <DialogTitle>
          <Typography component="p" variant="h4">
            Are you sure?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography component="p" variant="h6">
            Please confirm!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            onClick={submitHandler}
            sx={{ color: "#fff" }}
          >
            Ok
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseConfirm}
            sx={{ color: "#fff" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={size}
        open={!!modalId}
        onClose={handleClose}
      >
        <DialogTitle>
          {size === "lg" ? (
            <Typography component="p" variant="h4" align="center">
              Payouts Details
            </Typography>
          ) : (
            <Typography component="p" variant="h4" align="center" color="error">
              Reject
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          {size === "lg" && (
            <AproveContent
              id={modalId}
              amount={amount}
              cb={handleClose}
              confirmCb={handleOpenConfirm}
              payment={payment}
            />
          )}
          {size === "sm" && (
            <RejectContent
              id={modalId}
              cb={handleClose}
              confirmCb={handleOpenConfirm}
            />
          )}{" "}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PayOutModal;
