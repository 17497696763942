import { useState, useEffect } from "react";
import useDebounce from "src/hooks/debounce.hook";
import { InputAdornment, TextField } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";

const EmailSearch = ({ cb }) => {
  const [email, setEmail] = useState("");

  const debouncedSearchTerm = useDebounce(email, 300);
  useEffect(() => {
    cb({ email: debouncedSearchTerm });
  }, [debouncedSearchTerm]);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <TextField
        label="Search by email"
        name="email"
        type="text"
        value={email}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
        size="small"
        fullWidth
        sx={{ maxWidth: 220 }}
      />
    </>
  );
};

export default EmailSearch;
