import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export const useRequireAuth = (
  hideForAuthUsers = false,
  redirectUrl = "/login"
) => {
  const navigate = useNavigate();
  const token = Cookies.get(
    "ad_token",
    process.env.NODE_ENV === "production" ? { domain: "admin.kashly.net" } : {}
  );

  useEffect(() => {
    if (!hideForAuthUsers) {
      if (!token) {
        navigate(redirectUrl);
      }
    } else {
      if (!!token) {
        navigate(redirectUrl);
      }
    }
  }, [token, navigate, redirectUrl, hideForAuthUsers]);

  return !!token;
};
