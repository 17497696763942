import { takeLatest, put, call } from "redux-saga/effects";
import { types, initialPayoutsLoadSuccessAction } from "./payoutsActions";
import {
  showLoader,
  hideLoader,
  setAlertAction,
} from "src/redux/app/appActions";
import { getPayoutsList } from "src/api";
import getUrlParams from "src/utils/getUrlParams";

export function* payoutsWatcher() {
  yield takeLatest(types.INITIAL_PAYOUTS_LOAD, initialPayoutsLoadSaga);
}

function* initialPayoutsLoadSaga() {
  try {
    yield put(showLoader());

    const payoutsData = yield call(fetchPayoutsData);

    yield put(initialPayoutsLoadSuccessAction(payoutsData));

    yield put(hideLoader());
  } catch ({ data: error }) {
    yield put(
      setAlertAction({
        status: "error",
        message: error?.message || "load error",
      })
    );
    yield put(hideLoader());
  }
}

const fetchPayoutsData = async () => {
  const query = getUrlParams();
  const { data: payoutsData } = await getPayoutsList({
    size: query?.size || 25,
    page: query?.page || 1,
    admin: true,
  });

  return payoutsData;
};
