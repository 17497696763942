import { types } from "./ordersActions";

const initialState = {
  orders: {
    data: [],
    page: 0,
    count: 0,
    size: 25,
  },
  params: {
    email: null,
    storeId: null,
    start: null,
    end: null,
    status: null,
    sortProp: "ordered_at", // id, ordered_at
    sortOrder: "desc", // asc, desc
  },
  id: null,
};

export const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INITIAL_ORDERS_LOAD_SUCCESS:
      return {
        ...state,
        orders: action.orders,
      };

    case types.INITIAL_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };

    case types.REJECT_ORDER:
      let newOrders = [...state.orders.data].map((el) => {
        if (el.id === action.id) {
          el.status = -30;
          return el;
        } else return el;
      });
      return {
        ...state,
        orders: { ...state.orders, data: newOrders },
      };

    case types.BULK_REJECT_ORDERS:
      let temp = [...state.orders.data].map((el) => {
        if (action.ids.includes(el.id)) {
          el.status = -30;
          return el;
        } else return el;
      });
      return {
        ...state,
        orders: { ...state.orders, data: temp },
      };

    default:
      return state;
  }
};
