import axios from "axios";
import Cookies from "js-cookie";

const dev = process.env.NODE_ENV !== "production";

const server = axios.create({
  baseURL: "https://api.kash.ly",
  timeout: 60000,
});

server.interceptors.request.use(
  (config) => {
    let token = Cookies.get(
      "ad_token",
      process.env.NODE_ENV === "production"
        ? { domain: "admin.kashly.net" }
        : {}
    );
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

server.interceptors.response.use(
  (response) => {
    let res = response;

    if (undefined !== response.status) {
      res = response.data;
    } else {
      res = JSON.parse(response).data;
    }
    if (response.status !== 200 && response.status !== 201) {
      if (res.errno === 1101 || res.errno === 1100 || res.errno === 1201) {
      } else {
        console.log(res.errmsg || "Error");
      }
      return Promise.reject(new Error(res.errmsg || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    console.log(error.response);
    return Promise.reject(error.response);
  }
);

export default server;
