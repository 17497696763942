import PropTypes from "prop-types";
import {
  Checkbox,
  Button,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import getTimeStr from "src/utils/getTime";

const OrderTableBody = ({
  data,
  selectedOrderIds,
  handleSelectOne,
  openRejectCb,
}) => {
  const returnStatus = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 10:
        return "Available";
      case -20:
        return "Canceled";
      case 20:
        return "Cashing";
      case 50:
        return "Cashed";
      case -30:
        return "Rejected";

      default:
        return "";
    }
  };

  const returnStatusColor = (status) => {
    switch (status) {
      case 0:
        return "#F2994A";
      case 10:
        return "#5FE25CAB";
      case -20:
        return "#EA5456";
      case 20:
        return "#F2994A";
      case 50:
        return "#5FE25CAB";
      case -30:
        return "#EA5456";

      default:
        return "inherit";
    }
  };

  return (
    <>
      <TableRow hover selected={selectedOrderIds.indexOf(data.id) !== -1}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectedOrderIds.indexOf(data.id) !== -1}
            onChange={(event) => handleSelectOne(event, data.id)}
            value="true"
          />
        </TableCell>
        <TableCell>{data.orderNo}</TableCell>
        <TableCell>{data?.user?.email}</TableCell>
        <TableCell>{data?.storeName}</TableCell>
        <TableCell>{data?.affiliation}</TableCell>
        <TableCell>${data?.amount || 0}</TableCell>
        <TableCell>${data?.userCashback || 0}</TableCell>
        <TableCell>{getTimeStr(data.orderedAt || data.createdAt)}</TableCell>
        <TableCell>
          <Typography sx={{ color: returnStatusColor(data?.status) }}>
            {returnStatus(data?.status)}
          </Typography>
        </TableCell>
        <TableCell>
          <Button
            variant="outlined"
            size="small"
            disabled={
              (data?.status !== 0 && data?.status !== 10) ||
              !!selectedOrderIds.length
            }
            onClick={() => openRejectCb(data.id)}
            color="error"
          >
            Reject
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

OrderTableBody.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OrderTableBody;
