import PropTypes from "prop-types";
import {
  TableCell,
  TableRow,
  Button,
  Box,
  Typography,
} from "@material-ui/core";
import getTimeStr from "src/utils/getTime";

const LinkTableBody = ({ data, openDeleteDealModal }) => {
  return (
    <>
      <TableRow hover>
        <TableCell>{data?.user?.email}</TableCell>
        <TableCell>
          <Typography variant="body2" noWrap={true}>
            {getTimeStr(data.createdAt)}
          </Typography>
        </TableCell>
        <TableCell>
          <Box maxWidth="250px">
            <a href={data.link} target="_blank" rel="noopener noreferrer">
              <Typography variant="body2" className="title-2">
                {data?.link?.replace?.("https://", "")}
              </Typography>
            </a>
          </Box>
        </TableCell>
        <TableCell>
          <Box maxWidth="200px">
            <a href={data.shortLink} target="_blank" rel="noopener noreferrer">
              <Typography variant="body2" noWrap={true}>
                {data?.shortLink?.replace?.("https://", "")}
              </Typography>
            </a>
          </Box>
        </TableCell>

        <TableCell>{data?.clicks}</TableCell>

        <TableCell size="small">
          <Box maxWidth="200px">
            <Typography variant="body2" className="title-2">
              {data.comment}
            </Typography>
          </Box>
        </TableCell>
        <TableCell align="center">
          <Button
            color="warning"
            variant="text"
            onClick={() => openDeleteDealModal(data.id)}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

LinkTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  openDeleteDealModal: PropTypes.func.isRequired,
};

export default LinkTableBody;
