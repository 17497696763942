import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const RejectModal = ({ open, closeCb, rejectCb }) => {
  return (
    <Dialog fullWidth={true} maxWidth="xs" open={open} onClose={closeCb}>
      <DialogTitle>{"Reject"}</DialogTitle>
      <DialogContent>
        <DialogContentText>Reject order(s) forever?!</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeCb}
          sx={{
            color: "text.primary",
          }}
        >
          Cancel
        </Button>
        <Button onClick={rejectCb} color="error">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectModal;
