import { useState, useRef, useLayoutEffect } from "react";
import { TextField } from "@material-ui/core";
import { getSubtractDate } from "src/utils/getTime";
import getUrlParams from "src/utils/getUrlParams";

const DateInput = ({ cb }) => {
  const params = getUrlParams();

  const [date, setDate] = useState(params?.date || getSubtractDate(1));
  const dateInput = useRef(null);
  const [error, setError] = useState({
    status: false,
    text: "",
  });

  useLayoutEffect(() => {
    setDateRange();
  }, []);

  const setDateRange = () => {
    dateInput.current.min = getSubtractDate(90);
    dateInput.current.max = getSubtractDate(1);
  };

  const handleChange = (e) => {
    const val = e?.target?.value;
    console.log(val);
    let min = new Date(dateInput.current.min);
    let max = new Date(dateInput.current.max);

    if (val > max || val < min) {
      setError({
        status: true,
        text: "Incorrect entry.",
      });
      return;
    } else
      setError({
        status: false,
        text: "",
      });

    setDate(val);
    cb(val);
  };

  return (
    <TextField
      error={error.status}
      inputRef={dateInput}
      label="Select date"
      name="date"
      type="date"
      value={date}
      onChange={handleChange}
      helperText={error.text}
      InputLabelProps={{
        shrink: true,
      }}
      size="small"
      fullWidth
      sx={{ maxWidth: 200 }}
    />
  );
};

export default DateInput;
