import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  initialOrdersLoadAction,
  initialParamsAction,
  rejectOrderAction,
  bulkRejectOrdersAction,
} from "src/redux/orders/ordersActions";
import {
  getPageNum,
  getPageSize,
  getOrders,
  getTotalPages,
  getParams,
} from "src/redux/orders/ordersSelectors";
import getUrlParams from "src/utils/getUrlParams";
import { rejectOrder, bulkRejectOrders } from "src/api";

const useOrders = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const orders = useSelector(getOrders);
  const page = useSelector(getPageNum);
  const pageSize = useSelector(getPageSize);
  const totalPages = useSelector(getTotalPages);
  const { sortOrder } = useSelector(getParams);

  const [id, setId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [selectedOrderIds, setSelectedOrderIds] = useState([]);

  useEffect(() => {
    const query = getUrlParams();

    if (page !== (query?.page || 1)) dispatch(initialOrdersLoadAction());
  }, [dispatch]);

  const handleSelectAll = (event) => {
    let newSelectedOrderIds;

    if (event.target.checked) {
      newSelectedOrderIds = orders.map((order) => order.id);
    } else {
      newSelectedOrderIds = [];
    }

    setSelectedOrderIds(newSelectedOrderIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedOrderIds.indexOf(id);
    let newSelectedOrderIds = [];

    if (selectedIndex === -1) {
      newSelectedOrderIds = newSelectedOrderIds.concat(selectedOrderIds, id);
    } else if (selectedIndex === 0) {
      newSelectedOrderIds = newSelectedOrderIds.concat(
        selectedOrderIds.slice(1)
      );
    } else if (selectedIndex === selectedOrderIds.length - 1) {
      newSelectedOrderIds = newSelectedOrderIds.concat(
        selectedOrderIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedOrderIds = newSelectedOrderIds.concat(
        selectedOrderIds.slice(0, selectedIndex),
        selectedOrderIds.slice(selectedIndex + 1)
      );
    }

    setSelectedOrderIds(newSelectedOrderIds);
  };

  const handleLimitChange = (event) => {
    navigate(`/app/orders?page=1&size=${event.target.value}`);
    dispatch(initialOrdersLoadAction());
  };

  const handlePageChange = (event, newPage) => {
    navigate(`/app/orders?page=${newPage + 1}&size=${pageSize}`);
    dispatch(initialOrdersLoadAction());
  };

  const filterHanlder = ({ startDate, endDate }) => {
    navigate(`/app/orders?page=1&size=${pageSize}`);
    dispatch(initialParamsAction({ start: startDate, end: endDate }));
  };

  const updateParamsHandler = (param) => {
    dispatch(initialParamsAction(param));
  };

  const openRejectModal = (id = null) => {
    setId(id);
    setShowModal(true);
  };

  const closeRejectModal = () => {
    setId(null);
    setShowModal(false);
  };

  const rejectOrderHandler = async () => {
    try {
      if (!!id) {
        dispatch(rejectOrderAction(id));
        closeRejectModal();
        await rejectOrder(id);
      }
      if (!id && !!selectedOrderIds.length) {
        console.log(selectedOrderIds);
        dispatch(bulkRejectOrdersAction(selectedOrderIds));
        closeRejectModal();
        setSelectedOrderIds([]);
        await bulkRejectOrders({ orderIds: selectedOrderIds });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    orders,
    page,
    pageSize,
    totalPages,
    sortOrder,
    showModal,
    selectedOrderIds,
    handleSelectAll,
    handleSelectOne,
    openRejectModal,
    closeRejectModal,
    rejectOrderHandler,
    handleLimitChange,
    handlePageChange,
    filterHanlder,
    updateParamsHandler,
  };
};

export default useOrders;
