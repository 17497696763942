import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import PayOutsListResults from "src/components/payouts/PayOutsListResults";

const Payouts = () => {
  return (
    <>
      <Helmet>
        <title>Payouts | Kashly</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <PayOutsListResults />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Payouts;
