import { all, fork } from "redux-saga/effects";
import { usersWatcher } from "./users/usersSagas";
import { ordersWatcher } from "./orders/ordersSagas";
import { linksWatcher } from "./links/linksSagas";
import { payoutsWatcher } from "./payouts/payoutsSagas";
import { dashboardWatcher } from "./dashboard/dashboardSagas";

export function* rootSaga() {
  yield all([
    fork(usersWatcher),
    fork(ordersWatcher),
    fork(linksWatcher),
    fork(payoutsWatcher),
    fork(dashboardWatcher),
  ]);
}
