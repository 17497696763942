export const types = {
  INITIAL_PAYOUTS_LOAD: "PAYOUTS/INITIAL_PAYOUTS_LOAD",
  INITIAL_PAYOUTS_LOAD_SUCCESS: "PAYOUTS/INITIAL_PAYOUTS_LOAD_SUCCESS",
  INITIAL_PAYOUTS_MODAL: "PAYOUTS/INITIAL_PAYOUTS_MODAL",
};

export const initialPayoutsLoadAction = () => {
  return {
    type: types.INITIAL_PAYOUTS_LOAD,
  };
};

export const initialPayoutsLoadSuccessAction = (payouts) => {
  return {
    type: types.INITIAL_PAYOUTS_LOAD_SUCCESS,
    payouts,
  };
};

export const initialPayoutsModalAction = (modalData) => {
  return {
    type: types.INITIAL_PAYOUTS_MODAL,
    modal: modalData,
  };
};
