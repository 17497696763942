import moment from "moment-timezone";

const getTimeStr = (timestamp) => {
  return moment(timestamp).tz("America/Los_Angeles").format("MM.DD.YYYY");
};

export const getSubtractDate = (value, param = "days") => {
  return moment().subtract(value, param).format("YYYY-MM-DD");
};

export default getTimeStr;
